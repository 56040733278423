// Check if the passed element has a class
const hasClass = (el, className) => {
	return el.classList.contains(className);
};

// Add a class to the passed element
const addClass = (el, className) => {
	el.classList.add(className);
};

// Remove a class from the passed element
const removeClass = (el, className) => {
	el.classList.remove(className);
};

/**
 * Observe if an element is intersecting with another element or the viewport:
 * https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
 * Params:
 * - root => Element to observe, defaults to browser viewport
 * - rootMargin: "0%" => Margin around the root, px or %
 * - delay => 1000 Timeout before callback is triggered
 * - threshold: 1 => Triggers when element is visible for a certain amount,
 *   default 100%. Use 0 to trigger when element intersects with viewport
 * - threshold: [0.0, 0.25, 0.5, 0.75, 1.0] => Triggers callback every 25%
 */
const setIntersectionObserver = (callback, params = {}) => {
	return new IntersectionObserver((entries, observer) => {
		entries.forEach((e) => callback(e, observer));
	}, params);
};

/**
 * Observe if an element's dimensions are being changed:
 * https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver
 */
const setResizeObserver = (callback) => {
	return new ResizeObserver((entries, observer) => {
		entries.forEach((e) => callback(e, observer));
	});
};

/**
 * Observe if an element's DOM is updated or changed;
 * https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver/MutationObserver
 */
const setMutationObserver = (callback) => {
	return new MutationObserver((entries, observer) => {
		entries.forEach((e) => callback(e, observer));
	});
};

// Get an object of styles from the passed element
const getStyles = (el) => window.getComputedStyle(el);

// Get a property from the passed styles
const getStylesProp = (styles, prop) => styles.getPropertyValue(prop);

// Get an object of the passed styles, based on the passed array
const getStylesProps = (styles, props) =>
	props.reduce((obj, prop) => {
		return {
			...obj,
			[prop]: getStylesProp(styles, prop),
		};
	}, {});

export default {
	hasClass,
	addClass,
	removeClass,
	setIntersectionObserver,
	setResizeObserver,
	setMutationObserver,
	getStyles,
	getStylesProp,
	getStylesProps,
};
