import { initAddStoreToRoute } from "@cosh-eco/stores-module/dist/cosh-stores";

import { Element } from "@/scripts/extends";

export default class AddStoreToRoute extends Element {

	events() {
		this.el.addEventListener("click", (e) => this.onClick(e));
	}

	onClick() {
		const storeId = this.el.getAttribute("data-store-id");

		if (storeId) {
			initAddStoreToRoute("#cosh-stores-actions", {
				storeId,
			});
		}
	}

	destroy() {
		this.el.removeEventListener("click", () => this.onClick());
	}
}
