import { get, kebabCase } from "lodash-es";
import { Base } from "@/scripts/extends";

class DomManager extends Base {
	init() {
		this.components = {};
		this.modules = {};
		this.isDefined = false;
		this.isCreated = false;
		this.isMounted = false;
		this.isCompleted = false;
		this.createdCallback = () => null;
		this.mountedCallback = () => null;
		this.completedCallback = () => null;
	}

	destroy() {
		if (this.components && Object.keys(this.components).length !== 0) {
			Object.keys(this.components).forEach((key) => {
				if (this.components[key].length > 0) {
					this.components[key].forEach(instance => {
						if (typeof instance.destroy === "function") {
							instance.destroy();
						}
					});
				}
			});
		}

		this.components = {};
	}

	/**
	 * Set all ready state callbacks ready and define the onreadystatechange
	 * event. This can only be executed once when the javascript is initialized.
	 */
	define(params = {}) {
		if (this.isDefined) {
			return;
		}

		this.createdCallback = get(params, "created", this.createdCallback);
		this.mountedCallback = get(params, "mounted", this.mountedCallback);
		this.completedCallback = get(params, "completed", this.completedCallback);
		this.isDefined = true;

		document.onreadystatechange = () => this.onReadyStateChange();
	}

	// Functions to execute based on the ready state of the document
	onReadyStateChange() {
		this.created();

		if (document.readyState === "interactive") {
			this.mounted();
		}

		if (document.readyState === "complete") {
			// If interactive is not triggered, mounted is executed in complete
			this.mounted();
			this.completed();
		}
	}

	/**
	 * State initialized before the ready states, only executed once after
	 * executing define method
	 */
	created() {
		if (this.isCreated) {
			return;
		}

		this.createdCallback();

		this.isCreated = true;
	}

	/**
	 * State initialized on either interactive or complete state, only executed
	 * once after executing define method
	 */
	mounted() {
		if (this.isMounted) {
			return;
		}

		this.mountedCallback();

		this.isMounted = true;
	}

	/**
	 * State initialized on complete state, only executed once after executing
	 * define method
	 */
	completed() {
		if (this.isCompleted) {
			return;
		}

		this.completedCallback();

		this.isCompleted = true;
	}

	/**
	 * Pass in a selector and a Class and search the document or a different
	 * root DOM element for elements that match the selector and return the
	 * instances of the passed Class where the selected element is the root
	 * element of that instance.
	 */
	getInstances(selector, ClassDefinition, params = {}, options = {}) {
		const root = get(options, "root", document);
		const els = root.querySelectorAll(selector) || [];

		if (els.length === 0 || !ClassDefinition) {
			return [];
		}

		const instances = [...els].map((el) => new ClassDefinition(el, params)) || [];

		return instances;
	}

	// Add a component to the components collection
	registerComponent(selector, ClassDefinition, params = {}) {
		const instances = this.getInstances(selector, ClassDefinition, params);
		const classKey = ClassDefinition.name;

		this.components[classKey] = instances;
	}

	// Add a module to the modules collection
	registerModule(selector, ClassDefinition, params = {}) {
		const instances = this.getInstances(selector, ClassDefinition, params);
		const classKey = ClassDefinition.name;

		this.modules[classKey] = instances;
	}

	/**
	 * Pass in an object of classes and initialize the classes with their
	 * respective selectors. Return an object where each class type is an array
	 * of instances.
	 */
	setClasses(classes) {
		const classKeys = Object.keys(classes);

		return classKeys.reduce((obj, classKey) => {
			const selector = `.js-${kebabCase(classKey)}`;
			const ClassDefinition = get(classes, classKey, null);
			const instances = this.getInstances(selector, ClassDefinition);
			const newObj = obj;

			newObj[classKey] = instances;

			return newObj;
		}, {});
	}

	/**
	 * Set the components property by looping over all items defined in
	 * /scripts/components/index.js
	 */
	setComponents(components) {
		this.components = this.setClasses(components);
	}

	/**
	 * Set the modules property by looping over all items defined in
	 * /scripts/modules/index.js
	 */
	setModules(modules) {
		this.modules = this.setClasses(modules);
	}
}

const domManager = new DomManager();

export default domManager;
