import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { Element } from "@/scripts/extends";

const focusableSelector = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

export default class Menu extends Element {
	mounted() {
		this.toggleClass = "is-open";
		this.isOpen = false;

		this.id = this.el.getAttribute("data-trigger");
		this.contentEl = document.querySelector(`[data-content="${this.id}"]`);
		if (this.contentEl) {
			this.focusableEls = this.contentEl.querySelectorAll(focusableSelector);
		}

		this.initEvents();
	}

	handleTabs(e) {
		const firstFocusableElement = this.focusableEls[0];
		const lastFocusableElement = this.focusableEls[this.focusableEls.length - 1];

		let isTabPressed = e.key === "Tab";

		if (!isTabPressed) {
			return;
		}

		if (e.shiftKey) { // if shift key pressed for shift + tab combination
			if (document.activeElement === firstFocusableElement) {
				lastFocusableElement.focus(); // add focus for the last focusable element
				e.preventDefault();
			}
		} else { // if tab key is pressed
			if (document.activeElement === lastFocusableElement) { // if focused has reached to last focusable element then focus first focusable element after pressing tab
				firstFocusableElement.focus(); // add focus for the first focusable element
				e.preventDefault();
			}
		}
	}

	initEvents() {
		this.el.addEventListener("click", () => this.toggleState());
	}

	enableTabs() {
		this.focusableEls.forEach((el) => {
			el.setAttribute("tabindex", "");
		});

		if (this.focusableEls[0]) {
			this.focusableEls[0].focus();
		}
	}

	disableTabs() {
		this.focusableEls.forEach((el) => {
			el.setAttribute("tabindex", "-1");
		});
	}

	toggleState() {
		if (!this.el) {
			return;
		}

		if (this.el.classList.contains(this.toggleClass)) {
			this.handleClose();
		} else {
			this.handleOpen();
		}
	}

	handleOpen() {
		if (this.el && this.contentEl) {
			this.isOpen = true;

			this.el.classList.add(this.toggleClass);
			this.contentEl.classList.add(this.toggleClass);

			this.enableTabs();
			disableBodyScroll(this.contentEl);
			document.documentElement.style.overflow = "hidden";
		}
	}

	handleClose() {
		if (this.el && this.contentEl) {
			this.isOpen = false;

			this.el.classList.remove(this.toggleClass);
			this.contentEl.classList.remove(this.toggleClass);

			this.disableTabs();
			enableBodyScroll(this.contentEl);
			document.documentElement.style.overflow = "auto";
		}
	}
}
