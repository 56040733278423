import { Element } from "@/scripts/extends";

export default class Tabs extends Element {
	mounted() {
		this.activeTab = 0;

		this.inactiveClasses = ["text-neutral-400", "border-transparent", "hover:border-neutral-200", "hover:text-neutral-600"];
		this.activeClasses = ["is-active", "border-primary-800", "text-primary-800"];

		this.tabsEl = this.el.querySelectorAll("[data-trigger]");
		this.contentEls = this.el.querySelectorAll(".c-tabs__content-item");

		this.initEvents();
		this.initTabs();
	}

	initEvents() {
		this.tabsEl.forEach((tab, index) => {
			tab.addEventListener("click", () => this.toggleState(index));
		});
	}

	initTabs() {
		this.tabsEl.forEach((tab, index) => {
			if (index === this.activeTab) {
				this.setActive(tab);
			} else {
				this.setInactive(tab);
			}
		});
	}

	toggleState(index) {
		this.activeTab = index;

		this.tabsEl.forEach((tab, tabIndex) => {
			if (tabIndex === this.activeTab) {
				this.setActive(tab);
			} else {
				this.setInactive(tab);
			}
		});
	}

	setActive(element) {
		element.classList.remove(...this.inactiveClasses);
		element.classList.add(...this.activeClasses);

		let tag = element.querySelector(".c-tag");
		if (tag) {
			tag.classList.remove("c-tag--outline");
		}

		this.contentEls.forEach((tab, index) => {
			if (index === this.activeTab) {
				tab.classList.remove("hidden");
			} else {
				tab.classList.add("hidden");
			}
		});
	}

	setInactive(element) {
		element.classList.remove(...this.activeClasses);
		element.classList.add(...this.inactiveClasses);

		let tag = element.querySelector(".c-tag");
		if (tag) {
			tag.classList.add("c-tag--outline");
		}
	}

}
