import { initLikeEntry } from "@cosh-eco/stores-module/dist/cosh-stores";

import { Element } from "@/scripts/extends";

const TOGGLE_CLASS = "is-liked";

export default class FavouriteStore extends Element {
	events() {
		this.el.addEventListener("click", (e) => this.onClick(e));
	}

	onClick() {
		const vueEl = document.querySelector("#cosh-stores-actions");

		if (vueEl) {
			const userId = vueEl.getAttribute("data-user");
			if (userId) {
				this.setClasses();
			}
		}

		const entryId = this.el.getAttribute("data-entry-id");

		if (entryId) {
			initLikeEntry("#cosh-stores-actions", {
				entryId,
			});
		}
	}

	setClasses() {
		const isLiked = this.el.classList.contains(TOGGLE_CLASS);

		if (isLiked) {
			this.el.classList.remove(TOGGLE_CLASS);
		} else {
			this.el.classList.add(TOGGLE_CLASS);
		}
	}

	destroy() {
		this.el.removeEventListener("click", () => this.onClick());
	}
}
