import { Element } from "@/scripts/extends";
import { dom } from "@/scripts/utilities";

export default class Carousel extends Element {
	mounted() {
		this.cols = 3;

		this.sliderEl = this.el.querySelector(".c-gallery__slider");
		this.cardEls = this.el.querySelectorAll(".c-gallery__element");

		this.initEvents();
		this.initSizes();
	}

	initEvents() {
		this.resizeObserver = dom.setResizeObserver(() => this.initSizes());
		this.resizeObserver.observe(this.sliderEl);
	}

	destroy() {
		this.resizeObserver.disconnect();
	}

	initSizes() {
		const gapWidth = parseInt(window.getComputedStyle(this.sliderEl).getPropertyValue("gap"));
		const sliderWidth = this.sliderEl.offsetWidth - gapWidth * (this.cols - 1);
		this.cardEls.forEach((cardEl) => {
			cardEl.style.width = `${sliderWidth / this.cols}px`;
		});
	}

}
