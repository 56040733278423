import Swiper, { Autoplay } from "swiper";

import { Element } from "@/scripts/extends";

export default class Slider extends Element {
	mounted() {
		Swiper.use([Autoplay]);

		this.setSwiper();
	}

	setSwiper() {
		this.swiper = new Swiper(this.el, {
			direction: 'horizontal',
			slidesPerView: 2,
			spaceBetween: 30,
			wrapperClass: "c-slider__wrapper",
			slideClass: "c-slide",
			loop: true,
			autoplay: {
				delay: 2000,
				disableOnInteraction: false,
			},
			breakpoints: {
				320: {
					slidesPerView: 3,
				},
				768: {
					slidesPerView: 6,
				},
				1200: {
					slidesPerView: 7,
				},
				1440: {
					slidesPerView: 10,
				}
			}
		});

		this.swiper.init();
	}
}
