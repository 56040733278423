import { Element } from "@/scripts/extends";

export default class TrackingButton extends Element {

	events() {
		this.el.addEventListener("click", (e) => this.onClick(this.el, e));
	}

	onClick(el, e) {
		if (window.dataLayer) {
			let dataLayerProps = el.getAttribute("data-layer");
			let buttonTxt = el.querySelector(".c-button__inner");

			let dataLayerArr = {
				"event": "button_click",
				"button_label": el.innerText,
				"page_url": window.location.href,
			};

			if (dataLayerProps) {
				dataLayerArr = JSON.parse(dataLayerProps);
			}

			if (dataLayerArr) {
				window.dataLayer.push(dataLayerArr);
			}
		}
	}

	destroy() {
		this.el.removeEventListener("click", () => this.onClick());
	}
}
