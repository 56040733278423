
import Toastify from "toastify-js";
import { Element } from "@/scripts/extends";

const TOAST_LIMIT = 1;

export default class Copy extends Element {
	mounted() {
		this.initEvents();

		this.toastCount = 0;
	}

	initEvents() {
		this.el.addEventListener("click", () => this.copyText());
	}

	copyText() {
		const textToCopy = window.location.href;

		if (!navigator.clipboard) {
			// fallback
			const textArea = document.createElement('textarea');
			textArea.value = textToCopy;
			document.body.appendChild(textArea);
			textArea.focus();
			textArea.select();
			document.execCommand('copy');
			textArea.remove();
		} else {
			navigator.clipboard.writeText(textToCopy);
		}

		this.showToast();
	}

	showToast() {
		if (this.toastCount >= TOAST_LIMIT) {
			return;
		}

		const text = this.el.getAttribute("data-toast-message") || "Link copied";

		const toast = Toastify({
			id: "test",
			text: `<svg width="20" height="20" class="mr-2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M19.916 4.62596C20.2607 4.85573 20.3538 5.32138 20.124 5.66603L11.124 19.166C10.9994 19.353 10.7975 19.4742 10.5739 19.4963C10.3503 19.5185 10.1286 19.4392 9.96967 19.2803L3.96967 13.2803C3.67678 12.9874 3.67678 12.5126 3.96967 12.2197C4.26256 11.9268 4.73744 11.9268 5.03033 12.2197L10.3834 17.5728L18.876 4.83397C19.1057 4.48933 19.5714 4.3962 19.916 4.62596Z" fill="currentColor"/></svg>${text}`,
			escapeMarkup: false,
			position: "center",
			className: "c-toast",
			onClick: () => {
				toast.hideToast();
			},
			callback: () => {
				this.toastCount--;
			},
		});

		toast.showToast();
		this.toastCount++;
	}
}
