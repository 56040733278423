import { Element } from "@/scripts/extends";

export default class AdTracking extends Element {

	mounted() {
		this.addAdPageEvent();
	}

	addAdPageEvent() {
		if (window.dataLayer) {
			let dataLayerArr = {
				"event": "view_advertisement",
				"page_url": window.location.href,
				"ad_id": this.el.querySelector(".c-cta").getAttribute("data-cta-identifier"),
			};

			if (dataLayerArr) {
				window.dataLayer.push(dataLayerArr);
			}
		}
	}
}
