import { Element } from "@/scripts/extends";

export default class Gallery extends Element {
	mounted() {
		this.currentPosition = 0;

		this.prevEl = this.el.querySelector("[data-prev]");
		this.nextEl = this.el.querySelector("[data-next]");
		this.sliderEl = this.el.querySelector(".c-gallery__slider");

		this.initEvents();
		this.handleScroll();
	}

	initEvents() {
		this.prevEl.addEventListener("click", () => this.prev());
		this.nextEl.addEventListener("click", () => this.next());
		this.sliderEl.addEventListener("scroll", () => this.handleScroll());
	}

	destroy() {
		this.prevEl.removeEventListener("click", () => this.prev());
		this.nextEl.removeEventListener("click", () => this.next());
		this.sliderEl.removeEventListener("scroll", () => this.handleScroll());
	}

	prev() {
		if (this.currentPosition === 0) {
			return;
		}

		const offsetWidth = this.sliderEl.children[this.currentPosition].offsetWidth;
		this.sliderEl.scrollLeft -= offsetWidth;
	}

	next() {
		const pos = this.currentPosition;
		if (pos === this.sliderEl.children.length) {
			return;
		}

		const offsetWidth = this.sliderEl.children[this.currentPosition].offsetWidth;
		this.sliderEl.scrollLeft += offsetWidth;
	}

	gapWidth() {
		return parseInt(window.getComputedStyle(this.sliderEl).getPropertyValue("gap"));
	}

	setCurrentPosition() {
		const gapWidth = this.gapWidth();

		for (let i = 0; i < this.sliderEl.children.length; i++) {
			const itemEl = this.sliderEl.children[i];
			const offset = i * (itemEl.offsetWidth + gapWidth);

			if (offset >= this.sliderEl.scrollLeft) {
				this.currentPosition = i;

				break;
			}

		}
	}

	handleScroll() {
		if (this.sliderEl.scrollLeft === 0) {
			this.prevEl.classList.add("is-disabled");
			this.prevEl.disabled = true;
		} else {
			this.prevEl.classList.remove("is-disabled");
			this.prevEl.disabled = false;
		}

		if (Math.floor(this.sliderEl.scrollLeft) === Math.floor(this.sliderEl.scrollWidth - this.sliderEl.offsetWidth)) {
			this.nextEl.classList.add("is-disabled");
			this.nextEl.disabled = true;
		} else {
			this.nextEl.classList.remove("is-disabled");
			this.nextEl.disabled = false;
		}

		this.setCurrentPosition();
	}
}
