import { Element } from "@/scripts/extends";
import keyboard from "../singletons/Keyboard";

const focusableSelector = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

export default class Flyout extends Element {
	mounted() {
		this.toggleClass = "is-open";
		this.isOpen = false;

		this.triggerEl = this.el.querySelector("[data-trigger]");
		this.id = this.triggerEl.getAttribute("data-trigger");
		this.contentEl = this.el.querySelector(`[data-content="${this.id}"]`);
		this.focusableEls = this.contentEl.querySelectorAll(focusableSelector);

		this.toggleState = this.toggleState.bind(this);

		this.initKeyboard();
		this.disableTabs();
		this.initEvents();
	}

	initKeyboard() {
		keyboard.listenOnKeyDown((e) => this.onKeydown(e));
	}

	handleClickOutside(e) {
		if (!this.el.contains(e.target)) {
			this.handleClose();
		}
	}

	onKeydown(e) {
		if (!this.isOpen) {
			return;
		}

		this.handleTabs(e);
		this.handleEsc(e);
	}

	handleTabs(e) {
		const firstFocusableElement = this.focusableEls[0];
		const lastFocusableElement = this.focusableEls[this.focusableEls.length - 1];

		let isTabPressed = e.key === "Tab";

		if (!isTabPressed) {
			return;
		}

		if (e.shiftKey) { // if shift key pressed for shift + tab combination
			if (document.activeElement === firstFocusableElement) {
				lastFocusableElement.focus(); // add focus for the last focusable element
				e.preventDefault();
			}
		} else { // if tab key is pressed
			if (document.activeElement === lastFocusableElement) { // if focused has reached to last focusable element then focus first focusable element after pressing tab
				firstFocusableElement.focus(); // add focus for the first focusable element
				e.preventDefault();
			}
		}
	}

	handleEsc(e) {
		const isEsc = 27;

		if (e.keyCode === isEsc) {
			this.handleClose();
			e.preventDefault();
		}
	}

	initEvents() {
		this.triggerEl.addEventListener("click", this.toggleState);
	}

	destroy() {
		this.triggerEl.removeEventListener("click", this.toggleState);
	}

	enableTabs() {
		this.focusableEls.forEach((el) => {
			el.setAttribute("tabindex", "");
		});

		if (this.focusableEls[0]) {
			this.focusableEls[0].focus();
		}
	}

	disableTabs() {
		this.focusableEls.forEach((el) => {
			el.setAttribute("tabindex", "-1");
		});
	}

	toggleState() {
		if (!this.el) {
			return;
		}

		if (this.el.classList.contains(this.toggleClass)) {
			this.handleClose();
		} else {
			this.handleOpen();
		}
	}

	handleOpen() {
		if (this.el) {
			this.el.classList.add(this.toggleClass);
			this.isOpen = true;
			this.enableTabs();
			document.addEventListener('click', (e) => this.handleClickOutside(e));
		}
	}

	handleClose() {
		if (this.el) {
			this.el.classList.remove(this.toggleClass);
			this.isOpen = false;
			this.disableTabs();
			document.removeEventListener('click', (e) => this.handleClickOutside(e));
		}
	}
}
