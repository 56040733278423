import { Base } from "@/scripts/extends";
import { EVENTS } from "@/scripts/constants";
import { EventEmitter } from "@/scripts/core";

class Keyboard extends Base {
	events() {
		window.addEventListener("keydown", this.onKeyDown);
		window.addEventListener("keyup", this.onKeyUp);
		window.addEventListener("keypress", this.onKeyPress);
	}

	onKeyDown(e) {
		EventEmitter.emit(EVENTS.KEYBOARD_KEY_DOWN, e);
	}

	onKeyUp(e) {
		EventEmitter.emit(EVENTS.KEYBOARD_KEY_UP, e);
	}

	onKeyPress(e) {
		EventEmitter.emit(EVENTS.KEYBOARD_KEY_PRESS, e);
	}

	listenOnKeyDown(callback) {
		EventEmitter.on(EVENTS.KEYBOARD_KEY_DOWN, callback);
	}

	listenOnKeyUp(callback) {
		EventEmitter.on(EVENTS.KEYBOARD_KEY_UP, callback);
	}

	listenOnKeyPress(callback) {
		EventEmitter.on(EVENTS.KEYBOARD_KEY_PRESS, callback);
	}
}

const keyboard = new Keyboard();

export default keyboard;
