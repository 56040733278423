import { Element } from "@/scripts/extends";
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';


export default class Popup extends Element {
	mounted() {
		this.body = document.querySelector("body");
		this.mouseY = null;
		this.button = this.el.querySelector(".c-popup__close");
		this.backdrop = this.el.querySelector(".c-popup__backdrop");
		this.siteLanguage = document.getElementsByTagName("html")[0].getAttribute("lang")
		this.id = `${this.el.id}-${this.siteLanguage}`;
		this.appear = this.el.getAttribute("data-appear");
	}

	initialise() {
		this.sessionCookie = sessionStorage.getItem(this.id);

		if (!this.sessionCookie) {
			this.open();
		}

		sessionStorage.setItem(this.id, true);
	}

	events() {
		this.button.addEventListener("click", () => this.close());
		this.backdrop.addEventListener("click", () => this.close());

		if (this.appear === "on-leave") {
			if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
				setTimeout(() => this.initialise(), 5000);
			} else {
				this.body.addEventListener('mouseleave', (event) => {
					this.mouseY = event.clientY;
					if (this.mouseY < 0) {
						this.initialise();
					}
				});
			}
		} else {
			this.initialise();
		}
	}

	open() {
		this.el.classList.remove("hidden");
		this.el.classList.add("flex");
		disableBodyScroll(this.el);
		document.documentElement.style.overflow = "hidden";
	}

	close(e) {
		this.el.classList.add("hidden");
		enableBodyScroll(this.el);
		document.documentElement.style.overflow = "auto";
	}
}
