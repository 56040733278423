import { DomManager, Store } from "@/scripts/core";
import components from "@/scripts/components";
import modules from "@/scripts/modules";

/**
 * Import the main stylesheet, in production this will be extracted as a
 * separate css file.
 */
import "toastify-js/src/toastify.css";
import "lazysizes";
import "@/styles/main.css";
import "@/styles/cosh-stores.css";
import AOS from "aos";

/**
 * Set up the initial values and keys for the store. This should be done first
 * thing before mounting and creating the components.
 */
Store.setState({});

// EXTEND:Sprig + Vanilla JS
function init() {
	DomManager.setComponents(components);
	DomManager.setModules(modules);
}

DomManager.define({
	/**
	 * Triggers first and immediately, does not wait for a state. Only
	 * initialize DOM based classes that do not require calculations with
	 * dimensions, because the dimensions of certain elements might not be set
	 * immediately.
	 */
	created() {},

	/**
	 * Triggers on 'interactive' state, if 'interactive' never fires, it is
	 * triggered on 'complete' state. Dimensions are known and set
	 */
	mounted() {
		DomManager.setComponents(components);
		DomManager.setModules(modules);

		/**
		 * Initialize animate on scroll
		 */
		AOS.init({
			// Global settings:
			disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
			startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
			initClassName: "aos-init", // class applied after initialization
			animatedClassName: "aos-animate", // class applied on animation
			useClassNames: false, // if true, will add content of data-aos as classes on scroll
			disableMutationObserver: false, // disables automatic mutations' detections (advanced)
			debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
			throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
			// Settings that can be overridden on per-element basis, by data-aos-* attributes:
			offset: 120, // offset (in px) from the original trigger point
			delay: 0, // values from 0 to 3000, with step 50ms
			duration: 600, // values from 0 to 3000, with step 50ms
			easing: "ease-out-quad", // default easing for AOS animations
			once: true, // whether animation should happen only once - while scrolling down
			mirror: false, // whether elements should animate out while scrolling past them
			anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
		});

		document.querySelectorAll("img").forEach((img) => img.addEventListener("load", () => AOS.refresh()));
	},

	// Triggers last and on 'complete' state
	completed() {},
});

// EXTEND:Sprig + Vanilla JS
document.addEventListener("sprig-refresh", () => {
	DomManager.destroy();
	init();
});
